import { useWindowSize } from '@vueuse/core'
import { MobileView, type MobileViewKeys } from '@register'

export const useMobileViewStore = defineStore('mobileView', () => {
  // Determines if the PDF drawer is open on mobile
  const _mobileView = ref<MobileViewKeys>(MobileView.GROUPS)
  function setViewOnMobile(view: MobileViewKeys) {
    _mobileView.value = view
  }

  // The public `mobileView` is only available on small window sizes
  const windowSize = useWindowSize()
  const mobileView = computed(() => {
    if (windowSize.width.value < 768) {
      return _mobileView.value
    }
  })
  const isPDFMobileView = computed(
    () => mobileView.value && mobileView.value === MobileView.PDF,
  )
  const isGroupsMobileView = computed(
    () => mobileView.value && mobileView.value === MobileView.GROUPS,
  )
  const isFieldsMobileView = computed(
    () => mobileView.value && mobileView.value === MobileView.FIELDS,
  )

  const reset = () => {
    _mobileView.value = MobileView.GROUPS
  }

  return {
    mobileView,
    isPDFMobileView,
    isGroupsMobileView,
    isFieldsMobileView,
    setViewOnMobile,
    reset,
  }
})
